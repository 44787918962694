import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getWordsForDate } from './wordList';

interface ArchiveEntry {
  date: string;
  words: string[];
  timestamp: number;
  puzzleNumber: number;
}

function Archive() {
  const today = new Date();
  const navigate = useNavigate();
  
  const startDate = new Date('2024-11-02T05:00:00Z');
  const todayStart = new Date(Date.UTC(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate(),
    5, 0, 0, 0  // 5am UTC = 12am EST
  ));
  
  // Calculate today's puzzle number correctly
  const todayPuzzleNumber = Math.floor(
    (todayStart.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
  ) + 1;
  
  // Generate archive entries from start date to yesterday
  const archiveEntries: ArchiveEntry[] = [];
  
  // Start from yesterday and go back to start date
  for (let i = 1; i <= todayPuzzleNumber - 1; i++) {
    const date = new Date(todayStart);
    date.setUTCDate(date.getUTCDate() - i);
    date.setUTCHours(5, 0, 0, 0); // Ensure consistent time at EST midnight
    
    const words = getWordsForDate(date);
    const dateString = date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric',
      year: 'numeric'
    });
    
    archiveEntries.push({
      date: dateString,
      timestamp: date.getTime(),
      words: words,
      puzzleNumber: todayPuzzleNumber - i
    });
  }

  const isDarkMode = localStorage.getItem('isDarkMode') === 'true';

  const handlePuzzleClick = (timestamp: number) => {
    // Store the test date in localStorage
    localStorage.setItem('testDate', timestamp.toString());
    // Set flag for archived puzzle
    localStorage.setItem('isArchivedPuzzle', 'true');
    
    // Use separate keys for archived puzzles
    const keysToRemove = [
      `archived_gameOver_${timestamp}`,
      `archived_isWin_${timestamp}`,
      `archived_turnsLeft_${timestamp}`,
      `archived_lastPlayed_${timestamp}`,
      `archived_gameWords_${timestamp}`,
      `archived_incorrectWords_${timestamp}`,
      `archived_letterResults_${timestamp}`,
      `archived_showColors_${timestamp}`,
      `archived_hasSubmitted_${timestamp}`,
      `archived_revealedCorrectLetters_${timestamp}`,
      `archived_letterAttempts_${timestamp}`
    ];
    keysToRemove.forEach(key => localStorage.removeItem(key));
    
    // Navigate to the main game
    navigate('/');
  };

  return (
    <div style={{
      backgroundColor: isDarkMode ? '#1a1a1a' : '#f8edeb',
      minHeight: '100vh',
      padding: '20px',
      color: isDarkMode ? '#ffffff' : '#000000'
    }}>
      <div style={{
        maxWidth: '800px',
        margin: '0 auto',
        padding: '20px'
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '40px'
        }}>
          <h1 style={{ margin: 0 }}>Archive</h1>
          <Link
            to="/"
            onClick={() => {
              localStorage.removeItem('testDate');
              localStorage.removeItem('isArchivedPuzzle');
            }}
            style={{
              padding: '8px 16px',
              borderRadius: '8px',
              backgroundColor: isDarkMode ? '#4a3f42' : '#ECC9D0',
              color: isDarkMode ? '#ffffff' : '#000000',
              textDecoration: 'none',
              fontWeight: 'bold'
            }}
          >
            Today's Puzzle
          </Link>
        </div>

        <div style={{
          display: 'grid',
          gap: '16px'
        }}>
          {archiveEntries.map((entry, index) => (
            <div
              key={index}
              onClick={() => handlePuzzleClick(entry.timestamp)}
              style={{
                backgroundColor: isDarkMode ? '#2d2d2d' : 'white',
                padding: '20px',
                borderRadius: '12px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                cursor: 'pointer',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease'
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = 'translateY(-2px)';
                e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.2)';
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = 'none';
                e.currentTarget.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
              }}
            >
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '12px'
              }}>
                <h3 style={{ margin: 0 }}>
                  {entry.date} • #{entry.puzzleNumber}
                </h3>
                <div style={{
                  padding: '4px 8px',
                  borderRadius: '4px',
                  backgroundColor: isDarkMode ? '#4a3f42' : '#ECC9D0',
                  fontSize: '14px',
                  fontWeight: 'bold'
                }}>
                  Play
                </div>
              </div>
              <div style={{
                display: 'flex',
                gap: '8px',
                flexWrap: 'wrap',
                fontSize: '14px',
                color: isDarkMode ? '#cccccc' : '#666666'
              }}>
                <span>{entry.words[0]}</span>
                {entry.words.length > 2 && <span>...</span>}
                {entry.words.length > 1 && (
                  <>
                    <span>→</span>
                    <span>{entry.words[entry.words.length - 1]}</span>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Archive;